import jwtDecode from 'jwt-decode';
import axios from 'axios';

import config from '../../config';

// content type
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.baseURL = config.API_URL;


// intercepting to capture errors
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        let message;

        if (error && error.response && error.response.status === 404) {
             window.location.href = '/error-404';
        } else if (error && error.response && error.response.status === 403) {
            window.location.href = '/access-denied';
        }
        else if (error && error.response && error.response.status === 401) {
            sessionStorage.clear()
            window.location.href = '/login';
        }
        else if (error && error.response && error.response.status === 500) {
           // window.location.href = '/error-500';
        }
        else {
            switch (error.response.status) {
                case 401:
                    message = 'Invalid credentials';
                    break;
                case 500:
                    message = 'Invalid credentials';
                    break;
                case 403:
                    message = 'Access Forbidden';
                    break;
                case 404:
                    message = 'Sorry! the data you are looking for could not be found';
                    break;
                default: {
                    message =
                        error.response && error.response.data ? error.response.data['message'] : error.message || error;
                }
            }
            return Promise.reject(message);
        }
    }
);

const AUTH_SESSION_KEY = 'wundercoach_user';

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
    if (token) axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    else delete axios.defaults.headers.common['Authorization'];
};

const getUserFromSession = () => {
    const user = sessionStorage.getItem(AUTH_SESSION_KEY);
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

class APICore {
    /**
     * Fetches data from given url
     */
    get = (url, params, headers) => {
        let response;
        let account_id = localStorage.getItem("current_account");

        if(account_id){
            response = axios.get(`${url}?account_id=${account_id}`, headers);
        }else {
            response = axios.get(`${url}?account_id=${account_id}`, headers);
        }
        return response;
    };

    getFile = (url, params) => {
        let response;
        if (params) {
            var queryString = params
                ? Object.keys(params)
                    .map((key) => key + '=' + params[key])
                    .join('&')
                : '';
            response = axios.get(`${url}?${queryString}`, {responseType: 'blob'});
        } else {
            response = axios.get(`${url}`, {responseType: 'blob'});
        }
        return response;
    };

    getMultiple = (urls, params) => {
        const reqs = [];
        let queryString = '';
        if (params) {
            queryString = params
                ? Object.keys(params)
                    .map((key) => key + '=' + params[key])
                    .join('&')
                : '';
        }

        for (const url of urls) {
            reqs.push(axios.get(`${url}?${queryString}`));
        }
        return axios.all(reqs);
    };

    /**
     * post given data to url
     */
    create = (url, data, headers) => {
        let account_id = localStorage.getItem("current_account");
        let  newUrl = url
        if(account_id){
          newUrl = `${url}?account_id=${account_id}`
        }
        return axios.post(newUrl, data, headers);
    };

    /**
     * Updates patch data
     */
    updatePatch = (url, data) => {
        return axios.patch(url, data);
    };

    /**
     * Updates data
     */
    update = (url, data, headers) => {
        let account_id = localStorage.getItem("current_account");
        let  newUrl = url
        if(account_id){
            newUrl = `${url}?account_id=${account_id}`
        }
        return axios.put(newUrl, data, headers);
    };

    getWithoutQuery = (url, headers) => {
        let account_id = localStorage.getItem("current_account");
        let  newUrl = url
        if(account_id){
            newUrl = `${url}?account_id=${account_id}`
        }
        return axios.get(newUrl, headers);
    }

    /**
     * Deletes data
     */
    delete = (url, headers) => {
        let account_id = localStorage.getItem("current_account");
        let  newUrl = url
        if(account_id){
            newUrl = `${url}?account_id=${account_id}`
        }
        return axios.delete(newUrl, headers);
    };

    /**
     * post given data to url with file
     */
    createWithFile = (url, data) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const config = {
            headers: {
                ...axios.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post(url, formData, config);
    };

    /**
     * post given data to url with file
     */
    updateWithFile = (url, data) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const config = {
            headers: {
                ...axios.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axios.patch(url, formData, config);
    };

    isUserAuthenticated = () => {
        const user = this.getLoggedInUser();
        if (!user || (user && !user.token)) {
            return false;
        }

        const decoded = jwtDecode(user.token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            sessionStorage.clear()
            localStorage.clear()
            return false;
        } else {
            return true;
        }
    };

    setLoggedInUser = (session) => {
        if (session) sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
        else {
            sessionStorage.removeItem(AUTH_SESSION_KEY);
        }
    };

    /**
     * Returns the logged in user
     */
    getLoggedInUser = () => {
        return getUserFromSession();
    };

    setUserInSession = (modifiedUser) => {
        let userInfo = sessionStorage.getItem(AUTH_SESSION_KEY);
        if (userInfo) {
            const {token, user} = JSON.parse(userInfo);
            this.setLoggedInUser({token, ...user, ...modifiedUser});
        }
    };
}

/*
Check if token available in session
*/
let user = getUserFromSession();
if (user) {
    const {token} = user;
    if (token) {
        setAuthorization(token);
    }
}

export {APICore, setAuthorization};
