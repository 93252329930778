
export const WaitingListActionTypes = {
    API_RESPONSE_SUCCESS: '@@waitinglist_request/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@waitinglist_request/API_RESPONSE_ERROR',

    LIST_WAITING_LIST: '@@waitinglist_request/LIST_WAITING_LIST',
    SHOW_WAITING_LIST: '@@waitinglist_request/SHOW_WAITING_LIST',
    REMOVE_WAITING_LIST: '@@waitinglist_request/REMOVE_WAITING_LIST',
    CREATE_COMMENT: '@@waitinglist_request/CREATE_COMMENT',
    DELETE_COMMENT: '@@waitinglist_request/DELETE_COMMENT',
    FILTER_WAITING_LIST: '@@waitinglist_request/FILTER_WAITING_LIST',
};