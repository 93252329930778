import { WaitingListActionTypes } from './constants';

export const listWaitingLists = (actionType: string, data: any): WaitingListAction => ({
    type: WaitingListActionTypes.LIST_WAITING_LIST,
    payload: { data },
});

export const filterWaitingLists = (data: any): WaitingListAction => ({
    type: WaitingListActionTypes.FILTER_WAITING_LIST,
    payload: { data },
});

export const removeWaitingList = (data, id): WaitingListAction => ({
    type: WaitingListActionTypes.REMOVE_WAITING_LIST,
    payload: { data, id},
});

export const createComment = (data, id): WaitingListAction => ({
    type: WaitingListActionTypes.CREATE_COMMENT,
    payload: { data, id},
});

export const showWaitingList = (data: any): WaitingListAction => ({
    type: WaitingListActionTypes.SHOW_WAITING_LIST,
    payload: { data },
});

// common success
export const authApiResponseSuccess = (actionType: string, data: any): WaitingListAction => ({
    type: WaitingListActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const authApiResponseError = (actionType: string, error: string): WaitingListAction => ({
    type: WaitingListActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

