import { APICore } from './apiCore';

const api = new APICore();

function waitinglistList(): any {
    const baseUrl = '/eventbookings/waitinglist_bookings';
    return api.getWithoutQuery(`${baseUrl}`, { headers: { 'Content-Type': 'application/json', Accept: "*/*", Authorization: `Bearer ${api.getLoggedInUser().token}` }});
}


function waitinglistShow(params: any): any {
    const baseUrl = '/eventbookings/' + params;
    return api.getWithoutQuery(`${baseUrl}`, { headers: { 'Content-Type': 'application/json', Accept: "*/*", Authorization: `Bearer ${api.getLoggedInUser().token}` }});
}

function waitinglistRemove(params: any): any {
    const baseUrl = '/eventbookings/' + params + "/remove_from_waitinglist";
    return api.delete(`${baseUrl}`, { headers: { 'Content-Type': 'application/json', Accept: "*/*", Authorization: `Bearer ${api.getLoggedInUser().token}` }});
}

function waitinglistCreateComment(requestBody) {
    const baseUrl = '/eventbookings/' + requestBody.id + '/eventbooking_comments';
    return api.create(`${baseUrl}`, requestBody.data, { headers: { 'Content-Type': 'application/json', Accept: "*/*" }});
}

function waitinglistFilter(params: any): any {
    const baseUrl = '/eventbookings/waitinglist_bookings';

    let new_query_array = []
    Object.keys(params["data"]).map(function(key) {
        new_query_array.push(`q[${key}]=${params["data"][key]}`)
    });

    return api.get(`${baseUrl}?${new_query_array.join('&')}`, params,{ headers: { 'Content-Type': 'application/json', Accept: "*/*", Authorization: `Bearer ${api.getLoggedInUser().token}` }});
}

export { waitinglistList, waitinglistShow, waitinglistFilter, waitinglistCreateComment, waitinglistRemove };