// @flow
import { combineReducers } from 'redux';

import Auth from './auth/reducers';
import EventbookingReducer from './pages/eventbookings/reducers';
import EventReducer from './pages/events/reducers';
import DashboardReducer from './pages/dashboards/reducers';
import SurveyReducer from './pages/surveys/reducers';
import AssetReducer from './pages/assets/reducers';
import DigitalProductReducer from './pages/digital_products/reducers';
import BusinessdocumentReducer from './pages/businessdocuments/reducers';
import BookingrequestReducer from './pages/booking_requests/reducers';
import EventCoachReducer from './pages/event_coaches/reducers';
import Layout from './layout/reducers';
import WaitingListReducer from "./pages/waitinglists/reducers";

export default (combineReducers({
    Auth,
    Layout,
    EventbookingReducer,
    EventReducer,
    EventCoachReducer,
    SurveyReducer,
    AssetReducer,
    DashboardReducer,
    BusinessdocumentReducer,
    BookingrequestReducer,
    DigitalProductReducer,
    WaitingListReducer
}): any);
