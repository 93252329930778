import { WaitingListActionTypes } from './constants';

const WAITING_LIST_STATE = {
    waiting_lists: [],
    waiting_list: {},
    total_pages: 1,
    filters: { },
    loading: false,
};

type BookingrequestAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};
type State = { waiting_list?: {} | null, loading?: boolean, +value?: boolean };

const WaitingListReducer = (state: State = WAITING_LIST_STATE, action: WaitingListAction): any => {
    switch (action.type) {
        case WaitingListActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case WaitingListActionTypes.LIST_WAITING_LIST: {
                    return {
                        ...state,
                        waiting_lists: action.payload.data.waiting_lists,
                        total_pages: action.payload.data.total_pages,
                        loading: false,
                    };
                }
                case WaitingListActionTypes.SHOW_WAITING_LIST: {
                    return {
                        ...state,
                        waiting_list: action.payload.data,
                        loading: false,
                    };
                }
                case WaitingListActionTypes.CREATE_COMMENT: {
                    return {
                        ...state,
                        waiting_list: action.payload.data.waiting_list,
                        loading: false,
                    };
                }
                case WaitingListActionTypes.REMOVE_WAITING_LIST: {
                    return {
                        ...state,
                        loading: false,
                    };
                }
                case WaitingListActionTypes.FILTER_WAITING_LIST: {
                    return {
                        ...state,
                        waiting_lists: action.payload.data.waiting_lists,
                        total_pages: action.payload.data.total_pages,
                        filters: state.filters,
                        loading: false
                    };
                }
                default:
                    return {...state};
            }

        case WaitingListActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case WaitingListActionTypes.LIST_WAITING_LIST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        waiting_lists: [],
                        total_pages: 1,
                        loading: false,
                    };
                }
                case WaitingListActionTypes.SHOW_WAITING_LIST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        waiting_list: "",
                        loading: false,
                    };
                }
                case WaitingListActionTypes.REMOVE_WAITING_LIST: {
                    return {
                        ...state,
                        loading: false,
                    };
                }
                case WaitingListActionTypes.FILTER_WAITING_LIST: {
                    return {
                        ...state,
                        waiting_lists: action.payload.data,
                        filters: state.filters,
                        loading: false
                    };
                }
                default:
                    return {...state};
            }

        case WaitingListActionTypes.LIST_WAITING_LIST:
            return { ...state, loading: true, waiting_lists: [], total_pages: 1 };
        case WaitingListActionTypes.SHOW_WAITING_LIST:
            return { ...state, loading: true, waiting_list: "" };
        case WaitingListActionTypes.REMOVE_WAITING_LIST:
            return {...state, loading: false};
        case WaitingListActionTypes.FILTER_WAITING_LIST:
            return { ...state, loading: true, waiting_lists: state.waiting_lists, filters: state.filters };
        default:
            return { ...state };
    }
};

export default WaitingListReducer;