// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import {
    waitinglistList as waitinglistListApi,
    waitinglistShow as waitinglistShowApi,
    waitinglistRemove as waitinglistRemoveApi,
    waitinglistFilter as waitinglistFilterApi,
    waitinglistCreateComment as waitinglistCreateCommentApi,
} from "../../../helpers/api/waiting_list"

import {
    authApiResponseSuccess,
    authApiResponseError,
} from "./actions"
import { WaitingListActionTypes } from './constants';


function* list() {
    try {

        const response = yield call(waitinglistListApi);
        const waiting_lists = response.data

        yield put(authApiResponseSuccess(WaitingListActionTypes.LIST_WAITING_LIST, waiting_lists));
    } catch (error) {
        yield put(authApiResponseError(WaitingListActionTypes.LIST_WAITING_LIST, error));
    }
}

function* filter({ payload: { data } }) {
    try {
        const response = yield call(waitinglistFilterApi, { data });
        const waiting_lists = response.data;

        yield put(authApiResponseSuccess(WaitingListActionTypes.FILTER_WAITING_LIST, waiting_lists));
    } catch (error) {
        yield put(authApiResponseError(WaitingListActionTypes.FILTER_WAITING_LIST, error));
    }
}

function* show({ payload: { data } }) {
    try {
        const response = yield call(waitinglistShowApi, data);
        console.log(response)
        const waiting_list = response.data.eventbooking;

        yield put(authApiResponseSuccess(WaitingListActionTypes.SHOW_WAITING_LIST, waiting_list));
    } catch (error) {
        yield put(authApiResponseError(WaitingListActionTypes.SHOW_WAITING_LIST, error));
    }
}

function* remove({ payload: { data } }) {
    try {
        yield call(waitinglistRemoveApi, data);

        yield put(authApiResponseSuccess(WaitingListActionTypes.REMOVE_WAITING_LIST, {}));
    } catch (error) {
        yield put(authApiResponseError(WaitingListActionTypes.REMOVE_WAITING_LIST, error));
    }
}


function* createComment(action) {

    try {
        const response = yield call(waitinglistCreateCommentApi, {id: action.payload.id, data: action.payload.data});

        yield put(authApiResponseSuccess(WaitingListActionTypes.CREATE_COMMENT, {waiting_list: response.data.data.eventbooking, id: action.payload.data }));
    } catch (error) {
        yield put(authApiResponseError(WaitingListActionTypes.CREATE_COMMENT, error));
    }
}

export function* watchwaitinglistList(): any {
    yield takeEvery(WaitingListActionTypes.LIST_WAITING_LIST, list);
}

export function* watchwaitinglistFilter(): any {
    yield takeEvery(WaitingListActionTypes.FILTER_WAITING_LIST, filter);
}

export function* watchwaitinglistShow(): any {
    yield takeEvery(WaitingListActionTypes.SHOW_WAITING_LIST, show);
}

export function* watchwaitinglistRemove(): any {
    yield takeEvery(WaitingListActionTypes.REMOVE_WAITING_LIST, remove);
}

export function* watchwaitinglistCreateComment(): any {
    yield takeEvery(WaitingListActionTypes.CREATE_COMMENT, createComment);
}

function* WaitingListSaga(): any {
    yield all([
        fork(watchwaitinglistList),
        fork(watchwaitinglistShow),
        fork(watchwaitinglistRemove),
        fork(watchwaitinglistFilter),
        fork(watchwaitinglistCreateComment)
    ]);
}

export default WaitingListSaga;
